import React from 'react';
import { useGetProductsQuery } from '@typings/graph';
import { ProductsListPage } from '@components/page/Home/Products/productList';
import { LoadingPage } from '@components/LoadingPage';

export interface IProductsProps {
  datacenterId: number;
  brandId: number;
  brandName: string;
}
export interface IProductsPageFunctionProps {
  datacenterId: number;
  brandId: number;
}

export function ProductsPage({ datacenterId, brandId, brandName }: IProductsProps): JSX.Element {
  const { data } = useGetProductsQuery({
    variables: {
      datacenterId,
      brandId
    }
  });

  if (data?.getProducts?.products) {
    return <ProductsListPage products={data?.getProducts.products} brand={brandName} />;
  }
  return <LoadingPage />;
}
