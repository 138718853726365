import React, { useCallback } from 'react';
import Panel from '@cvent/carina/components/Panel';
import { Row } from '@cvent/carina/components/Row';
import { Col } from '@cvent/carina/components/Col';
import ScrollViewWithBars from '@cvent/carina/components/ScrollViewWithBars/ScrollViewWithBars';
import DismissButton from '@cvent/carina/components/ScrollViewWithBars/DismissButton';
import { IncidentComponent } from '@components/page/incident';
import { panelDCID, panelDismissButtonDCID } from '@dataCventIds';

export interface IPanelProps {
  incidentId: string;
  isPanelOpen: boolean;
  setIsPanelOpen: (isPanelOpen: boolean) => void;
  setSelectedIncidentId: (selectedIncidentId: string) => void;
}

export function PanelComponent({
  incidentId,
  isPanelOpen,
  setIsPanelOpen,
  setSelectedIncidentId
}: IPanelProps): JSX.Element {
  const onDismiss = useCallback(() => {
    setIsPanelOpen(false);
  }, [setIsPanelOpen]);
  // added this calculation for maxheight because the panel height expends to the full height and not just the content area that adds the unnecessary scroll
  const clientHeight = `${document.getElementById('mainBodyDiv')?.parentElement?.clientHeight}px`;
  return (
    <div style={{ maxHeight: clientHeight || 'auto', zIndex: 20 }}>
      <Panel
        isOpen={isPanelOpen}
        onDismiss={() => onDismiss()}
        testID={`${panelDCID}-${incidentId}`}
        onAnimationOut={() => setSelectedIncidentId(undefined)}
      >
        <ScrollViewWithBars>
          <Row margin={{ top: 16, start: 8, end: 8 }} justifyContent="flex-end">
            <Col width="content">
              {/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */}
              <DismissButton onClick={() => onDismiss()} testID={panelDismissButtonDCID} />
            </Col>
          </Row>

          <IncidentComponent incidentId={incidentId} panelView />
        </ScrollViewWithBars>
      </Panel>
    </div>
  );
}
