import React, { useState } from 'react';
import { Product, CurrentSeverityValues, useGetOngoingIncidentsQuery } from '@typings/graph';
import { Row } from '@cvent/carina/components/Row';
import { Col } from '@cvent/carina/components/Col';
import Accordion from '@cvent/carina/components/Accordion/Accordion';
import { homePageDCID } from '@dataCventIds';
import { SubsystemsPage } from '@components/page/Home/Subsystems';
import { CommonStatusLabel } from '@components/CommonStatusLabelComponent';
import { CommonContentContainer } from '@components/CommonContentContainer';
import { PanelComponent } from '@components/Panel';
import { usePageStyles } from '@components/styles';
import useStyle from '@hooks/useThemeHelper';
import { LoadingPage } from '@components/LoadingPage';

export interface IProductsListProps {
  products: Product[];
  brand: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function getAccordionSection(
  product,
  isPanelOpen,
  setIsPanelOpen,
  selectedIncidentId,
  setSelectedIncidentId,
  productsPageStyling,
  ongoingIncidents
) {
  const orderedSubsystems = [...product.subsystems].sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1)) || [];
  const sections = [];
  const section = {
    label: (
      <Row testID={product.name}>
        <Col
          {...productsPageStyling.responsiveProductDisplayColProps}
          css={{ ...productsPageStyling.productDisplayStyling }}
        >
          {product.name}
        </Col>
        <Col
          {...productsPageStyling.responsiveProductSeverityColProps}
          testID={`${product.id}-${product?.currentSeverity?.currentSeverity}` || CurrentSeverityValues.Operational}
        >
          <CommonStatusLabel
            incidentSeverity={product?.currentSeverity?.currentSeverity || CurrentSeverityValues.Operational}
            css={{}}
          />
        </Col>
      </Row>
    ),
    expandedByDefault: product.currentSeverity?.currentSeverity,
    content: (
      <SubsystemsPage
        subsystems={orderedSubsystems}
        isPanelOpen={isPanelOpen}
        setSelectedIncidentId={setSelectedIncidentId}
        setIsPanelOpen={setIsPanelOpen}
        selectedIncidentId={selectedIncidentId}
        ongoingIncidentsList={ongoingIncidents}
      />
    )
  };
  sections.push(section);
  return sections;
}

export function ProductsListPage({ products, brand }: IProductsListProps): JSX.Element {
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
  const [selectedIncidentId, setSelectedIncidentId] = useState<string>(undefined);

  const { data, loading } = useGetOngoingIncidentsQuery();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { productsPage } = useStyle(usePageStyles);
  const titleDivStyling = isPanelOpen
    ? productsPage.panelOpenBrandNameDivStyling
    : productsPage.panelClosedBrandNameDivStyling;
  const containerStyling = isPanelOpen
    ? productsPage.panelOpenCommonContentContainerStyling
    : productsPage.panelClosedCommonContentContainerStyling;
  const productList = [...products]?.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1)) || [];
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Row>
      <Col width={isPanelOpen ? 2 / 3 : 1} flex={{ justifyContent: 'center', flexDirection: 'column' }}>
        <div style={titleDivStyling}>
          <h3>{brand}</h3>
        </div>
        <CommonContentContainer width={containerStyling.width} styling={containerStyling}>
          {productList.map(p => (
            <Accordion
              sections={getAccordionSection(
                p,
                isPanelOpen,
                setIsPanelOpen,
                selectedIncidentId,
                setSelectedIncidentId,
                productsPage,
                data?.getOnGoingIncidents?.incidents || []
              )}
              allowMultipleOpen
              testID={`${homePageDCID.subsystemTable}-${p.name}`}
            />
          ))}
        </CommonContentContainer>
      </Col>
      {isPanelOpen && (
        <Col flex={{ display: 'flex', justifyContent: 'right' }} width={1 / 3} padding={{ end: 0 }}>
          <PanelComponent
            incidentId={selectedIncidentId}
            setIsPanelOpen={setIsPanelOpen}
            isPanelOpen={isPanelOpen}
            setSelectedIncidentId={setSelectedIncidentId}
          />
        </Col>
      )}
    </Row>
  );
}
