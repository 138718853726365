import React, { useCallback } from 'react';
import { CurrentSeverityValues, Incident, Subsystem } from '@typings/graph';
import { Row } from '@cvent/carina/components/Row';
import { Col } from '@cvent/carina/components/Col';
import { TextLink } from '@cvent/carina/components/TextLink';
import { getStatusName, StatusIcons } from '@components/page/Home/Products/incidentStatusIcons';
import useStyle from '@hooks/useThemeHelper';
import { usePageStyles } from '@components/styles';
import { Placements } from '@cvent/carina/components/Popover';
import { Tooltip } from '@cvent/carina/components/Tooltip';
import { getIncidentSeverity } from '@components/page/incident/common';

function StatusIconTriggerElement(incidentSeverity: CurrentSeverityValues): JSX.Element {
  return (
    <div>
      <StatusIcons severity={incidentSeverity} />
    </div>
  );
}

function filterOngoingIncidentImpactingSubsystem(
  datacenterSubsystemId: number,
  ongoingIncidents: Incident[]
): Incident[] {
  return (
    ongoingIncidents.filter(
      i =>
        i.incidentSubsystems.findIndex(
          incidentSub =>
            incidentSub.datacenterSubsystemId === datacenterSubsystemId &&
            (!incidentSub.endDate ||
              false ||
              (incidentSub.endDate &&
                new Date(incidentSub.startDate).getTime() <= new Date().getTime() && // adding condition for maintenance issues. Get incidents where incident end date exists and current date is greater than the start date
                new Date(incidentSub?.endDate).getTime() >= new Date().getTime() && // incident end date is greater than the current date
                incidentSub.severity === CurrentSeverityValues.Information)) // incident severity type is information
        ) > -1
    ) || []
  );
}

const subsystemItems = (
  subsystemList,
  setSelectedIncidentId,
  onIncidentIdClick,
  isPanelOpen,
  subsystemPageStyling,
  ongoingIncidents
) => {
  const items = [];
  const responsiveFixedIncidentColProps = isPanelOpen
    ? subsystemPageStyling.panelOpenResponsiveFixedIncidentColProps
    : subsystemPageStyling.panelClosedResponsiveFixedIncidentColProps;
  const { responsiveStatusColProps } = subsystemPageStyling;
  const { responsiveOperationalStatusColProps } = subsystemPageStyling;

  const { responsiveSubsystemDisplayNameColProps } = subsystemPageStyling;
  // eslint-disable-next-line array-callback-return
  subsystemList.map(s => {
    const subsystemsOngoingIncident = filterOngoingIncidentImpactingSubsystem(
      s.datacenterSubsystemId,
      ongoingIncidents
    );
    items.push({
      children: (
        <div style={{ width: '100%', border: 'none', marginTop: '20px' }}>
          <Row key={`${s.name}-${s.id}`} {...subsystemPageStyling.subsystemListRowProps}>
            <Col {...responsiveSubsystemDisplayNameColProps}>{s.name}</Col>
            {subsystemsOngoingIncident.length > 0 &&
              subsystemsOngoingIncident.map((i, index) => {
                const incidentSeverity = getIncidentSeverity(i.incidentSubsystems);
                if (index === 0) {
                  return (
                    <Col
                      width="fill"
                      flex={{ display: 'flex', justifyContent: 'flex-end' }}
                      offsetEnd={0}
                      padding={{ end: 0 }}
                    >
                      <Col {...responsiveFixedIncidentColProps}>
                        <TextLink
                          text={i.jiraId.replace('INC-', 'Incident #')}
                          onClick={() => {
                            setSelectedIncidentId(i.id);
                            onIncidentIdClick();
                          }}
                          testID={`${s.name}-${i.jiraId}`}
                        />
                      </Col>
                      <Col {...responsiveStatusColProps} testID={`${s.name}-${i.jiraId}-${incidentSeverity}`}>
                        <Tooltip
                          text={getStatusName(i.severity)}
                          accessibilityLabel="Incident Status"
                          trigger={StatusIconTriggerElement(incidentSeverity)}
                        />
                      </Col>
                    </Col>
                  );
                }
                return (
                  <Row margin={{ top: 10 }}>
                    <Col {...responsiveSubsystemDisplayNameColProps} />
                    <Col
                      width="fill"
                      flex={{ display: 'flex', justifyContent: 'flex-end' }}
                      offsetEnd={0}
                      padding={{ end: 0 }}
                    >
                      <Col {...responsiveFixedIncidentColProps}>
                        <TextLink
                          text={i.jiraId.replace('INC-', 'Incident #')}
                          onClick={() => {
                            setSelectedIncidentId(i.id);
                            onIncidentIdClick();
                          }}
                          testID={`${s.name}-${i.jiraId}`}
                        />
                      </Col>
                      <Col {...responsiveStatusColProps} testID={`${s.name}-${i.jiraId}-${i.severity}`}>
                        <Tooltip
                          placement={Placements.top}
                          text={getStatusName(i.severity)}
                          trigger={StatusIconTriggerElement(incidentSeverity)}
                        />
                      </Col>
                    </Col>
                  </Row>
                );
              })}
            {subsystemsOngoingIncident.length === 0 && (
              <Col {...responsiveOperationalStatusColProps}>
                <StatusIcons severity={CurrentSeverityValues.Operational} />
              </Col>
            )}
          </Row>
        </div>
      )
    });
  });
  return items;
};

export interface ISubsystemsProps {
  subsystems: Subsystem[];
  isPanelOpen: boolean;
  setIsPanelOpen: (isPanelOpen: boolean) => void;
  setSelectedIncidentId: (selectedIncidentId: string) => void;
  ongoingIncidentsList: Incident[];
  selectedIncidentId: string;
}

export function SubsystemsPage({
  subsystems,
  isPanelOpen,
  setIsPanelOpen,
  selectedIncidentId,
  setSelectedIncidentId,
  ongoingIncidentsList
}: ISubsystemsProps): JSX.Element {
  const { subsystemsPage } = useStyle(usePageStyles);
  const onIncidentIdClick = useCallback(
    () => {
      setIsPanelOpen(true);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedIncidentId]
  );

  return (
    <>
      {subsystemItems(
        subsystems,
        setSelectedIncidentId,
        onIncidentIdClick,
        isPanelOpen,
        subsystemsPage,
        ongoingIncidentsList
      ).map(s => s.children)}
    </>
  );
}
