import { ProductsPage } from '@components/page/Home/Products';
import { LoadingPage } from '@components/LoadingPage';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import React from 'react';
import type { AuthProps } from '@cvent/nextjs/auth';
import { statusSiteGetAuthPropsOrRedirect, handleNextJsError } from '@nextjsServer';
import { useGetHomePageNavMetaData } from '@hooks';
import Page from '@components/Page';
import { useDatacentersQuery, useBrandsQuery } from '@typings/graph';
import { Breakpoints } from '@cvent/carina/components/Breakpoints/Breakpoints';
import Router from 'next/router';

interface IBrandProps {
  region?: string;
  brand?: string;
}

type BrandPageServerSideProps = AuthProps & IBrandProps;

export const getServerSideProps = async (
  context: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<BrandPageServerSideProps>> => {
  try {
    const authProps = await statusSiteGetAuthPropsOrRedirect(context);
    const region = (context.query.region as string) || '';
    const brand = (context.query.brand as string) || '';

    return {
      props: {
        ...authProps,
        region,
        brand
      }
    };
  } catch (err) {
    return handleNextJsError(err);
  }
};

export default function BrandPageProps({ region, brand }: IBrandProps): JSX.Element {
  const navMetaData = useGetHomePageNavMetaData(brand as string, `/${region}/${brand}`, region, brand);

  const { data: datacenterResult } = useDatacentersQuery();
  const { data: brandsResult } = useBrandsQuery();

  // get brands and regions and then filter the required brand and region from the list
  if (datacenterResult?.datacenters?.datacenters) {
    const filteredRegion = datacenterResult?.datacenters.datacenters.find(
      regionObj => regionObj?.name === ((region as string).toUpperCase() || region || 'NA')
    );

    const filteredBrand = brandsResult?.brands.brands.find(
      brandObj =>
        brandObj.name.toUpperCase() ===
        ((brand as string).toUpperCase() || brand.toUpperCase() || 'Event Marketing & Management'.toUpperCase())
    );

    if (!filteredBrand || !filteredRegion) {
      Router.push('/NA/Event Marketing & Management');
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return null;
    }
    return (
      filteredRegion &&
      filteredBrand && (
        <Breakpoints m={680}>
          <Page
            pageTitle={(brand as string) || brand || 'Event Marketing & Management'}
            navMetadata={navMetaData}
            brandsList={brandsResult.brands?.brands}
            regionList={datacenterResult.datacenters?.datacenters}
            backgroundColor="#F7F8F9"
          >
            <ProductsPage
              datacenterId={Number(filteredRegion.id) || 1}
              brandId={filteredBrand.id || 1}
              brandName={filteredBrand.name}
            />
          </Page>
        </Breakpoints>
      )
    );
  }
  return <LoadingPage />;
}
